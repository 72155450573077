import { Auth } from '../components/auth';
import '../styles/index.css'
import '../styles/helper.css'
import '../styles/assetBrowser.css'
export const Login = () => {
    return (
        <div className='auth-wrapper'>
            <Auth></Auth>
        </div>

    );
}